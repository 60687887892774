import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BranchCard } from "./components/BranchCard";
import { BranchIT } from "./interface";
import {
  createBranch,
  getBranchConfig,
  updateBranch,
  uploadImages,
} from "./service";
import { useToken } from "src/utils/token";
import { useSnackbar } from "src/components/CustomSnackbar";
import CreateBranchModal from "./components/CreateBranchModal";
import { generateKeyFromTitle } from "src/utils/generateKeyFormTitle";

const Branch = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { showSnackbar } = useSnackbar();
  const token = useToken();

  const [branches, setBranches] = useState<BranchIT[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [branchData, setBranchData] = useState<
    {
      key: string;
      title: string;
      image: string;
      bin: string;
      logo: string;
      phone: string;
      card_id: string;
      card_name: string;
      shortName: string;
    }[]
  >([
    {
      key: "",
      title: "",
      image: "",
      bin: "",
      logo: "",
      phone: "",
      card_id: "",
      card_name: "",
      shortName: "",
    },
  ]);
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [selectedBranchIndex, setSelectedBranchIndex] = useState<number | null>(
    null
  );
  const [uploading, setUploading] = useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const data = await getBranchConfig("branch", token);
        setBranches(data.config.option_content);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching branches:", error);
        setLoading(false);
      }
    };

    fetchBranches();
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
    setStep(1);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleNextStep = () => setStep((prev) => prev + 1);
  const handlePreviousStep = () => setStep((prev) => prev - 1);

  const handleBranchDataChange = (field: string, value: string) => {
    setBranchData((prev) =>
      prev.map((branch, index) =>
        index === 0
          ? {
              ...branch,
              [field]: value,
            }
          : branch
      )
    );
  };

  const handleBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      console.log("Selected file:", selectedFile);

      try {
        setUploading(true);

        const headers = {
          "X-Authorization": token,
        };

        const uploadedImageUrl = await uploadImages(selectedFile, headers);

        setImageURL(uploadedImageUrl.src);
        showSnackbar("Hình ảnh đã tải lên thành công", "success");
      } catch (error) {
        showSnackbar("Hình ảnh đã tải lên thất bại", "error");
      } finally {
        setUploading(false);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const formattedData = [
        ...branches,
        ...branchData.map((branch) => ({
          key: branch.key,
          title: branch.title,
          image: imageURL || "",
          bin: branch.bin || "",
          logo: branch.logo || "",
          phone: branch.phone || "",
          card_id: branch.card_id || "",
          card_name: branch.card_name || "",
          shortName: branch.shortName || "",
        })),
      ];

      const response = await createBranch(
        {
          type: "branch",
          option_content: JSON.stringify(formattedData),
        },
        token || ""
      );

      setOpenModal(false);
      showSnackbar("Tạo chi nhánh thành công!", "success");

      setBranches(formattedData);
    } catch (error) {
      showSnackbar("Tạo chi nhánh thất bại.", "error");
    }
  };

  const handleUpdate = async (updatedBranch: BranchIT) => {
    if (selectedBranchIndex === null) return;

    try {
      const updatedBranches = branches.map((branch, index) =>
        index === selectedBranchIndex ? updatedBranch : branch
      );

      const response = await updateBranch(
        {
          type: "branch",
          option_content: JSON.stringify(updatedBranches),
        },
        token || ""
      );
      showSnackbar("Cập nhật chi nhánh thành công!", "success");
      setBranches(updatedBranches);
    } catch (error) {
      showSnackbar("Cập nhật chi nhánh thất bại.", "error");
    }
  };

  const isMobile = useMediaQuery("(max-width: 600px)");
  const isMobileSmall = useMediaQuery("(max-width: 375px)");
  const isIphone14 = useMediaQuery("(max-width: 430px)");

  return (
    <Box
      sx={{ mt: isMobile ? 5 : "none" }}
      width="100%"
      p={2}
      pt={4}
      maxWidth={1200}
      margin="0 auto"
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        gap={isMobile ? 2 : 0}
        mb={2}
      >
        <Typography variant="h5" fontWeight={550}>
          Danh sách chi nhánh
        </Typography>
        <Stack direction="row" gap={2} alignItems="center">
          <Button
            variant="contained"
            onClick={handleOpenModal}
            disabled={loading}
            sx={{
              textTransform: "none",
              fontWeight: 550,
              bgcolor: loading ? "#ccc" : "#ff7840",
              "&:hover": {
                bgcolor: loading ? "#ccc" : "#E00E0E",
              },
            }}
          >
            Tạo chi nhánh
          </Button>
        </Stack>
      </Stack>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <BranchCard
          branches={branches}
          handleUpdate={handleUpdate}
          setSelectedBranchIndex={setSelectedBranchIndex}
        />
      )}

      <CreateBranchModal
        open={openModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmit}
        handleFileChange={handleFileChange}
        handleBranchDataChange={handleBranchDataChange}
        uploading={uploading}
        imageURL={imageURL}
        branchData={branchData}
        step={step}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
        fileInputRef={fileInputRef}
      />
    </Box>
  );
};

export default Branch;
