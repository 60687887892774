import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Typography,
  Stack,
  Chip,
  Modal,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { FoodItem } from "../interface";
import { getConfigCategory, updateFood, uploadImages } from "../service";
import { useToken } from "src/utils/token";
import { BranchIT } from "src/pages/Branch/interface";
import { getBranchConfig } from "src/pages/Home/service";
import { useSnackbar } from "src/components/CustomSnackbar";
import { useSelector } from "react-redux";
import { RootState } from "src/redux-persist/store";

interface EditFoodModalProps {
  open: boolean;
  onClose: () => void;
  food: FoodItem | null;
  setFoods: React.Dispatch<React.SetStateAction<FoodItem[]>>;
}

interface Variant {
  sku: string;
  size: string;
  price: number;
  quantity: number;
}

const EditFoodModal: React.FC<EditFoodModalProps> = ({
  open,
  onClose,
  food,
  setFoods,
}) => {
  const token = useToken();
  const { showSnackbar } = useSnackbar();
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const userBranch = useSelector((state: RootState) => state.user.userBranch);

  const [foodName, setFoodName] = useState(food?.name || "");
  const [description, setDescription] = useState(food?.description || "");
  const [imageURL, setImageURL] = useState(food?.url || "");
  const [uploading, setUploading] = useState(false);
  const [categories, setCategories] = useState<
    {
      title: string;
      category: string[];
      _id: string;
      desVi: string;
      desEn: string;
      desJp: string;
      image: string;
    }[]
  >([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("");

  const [variants, setVariants] = useState<Variant[]>(
    food?.variants.map((variant) => ({
      sku: variant.sku,
      size: variant.size,
      price: variant.price || 0,
      quantity: variant.quantity || 0,
    })) || [
      { sku: "", size: "S", price: 0, quantity: 0 },
      { sku: "", size: "M", price: 0, quantity: 0 },
      { sku: "", size: "L", price: 0, quantity: 0 },
    ]
  );

  const [branches, setBranches] = useState<BranchIT[]>([]);
  const [branchInput, setBranchInput] = useState<string>(food?.branch || "");
  const [selectedCategory, setSelectedCategory] = useState<string>(
    food?.category[0] || ""
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (userRole === "super-admin") {
      if (branches.length > 0) {
        setSelectedBranch(branches[0].key);
      }
    } else if (userBranch) {
      setSelectedBranch(userBranch);
    } else {
      setSelectedBranch("duongkhue");
    }
  }, [userBranch, userRole, branches]);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const data = await getBranchConfig("branch", token || "");
        setBranches(data.config.option_content);

        if (!branchInput && data.config.option_content.length > 0) {
          setBranchInput(data.config.option_content[0].key);
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchBranches();
  }, [token, branchInput]);

  useEffect(() => {
    const fetchCategories = async () => {
      if (!branchInput) return;

      try {
        const response = await getConfigCategory(branchInput);
        setCategories(response.data.config.option_content || []);
        setSelectedCategory("");
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [branchInput]);

  useEffect(() => {
    if (food) {
      setFoodName(food.name || "");
      setDescription(food.description || "");
      setImageURL(food.url || "");
      setBranchInput(food.branch || "");
      setVariants(
        food.variants.map((variant) => ({
          sku: variant.sku,
          size: variant.size,
          price: variant.price || 0,
          quantity: variant.quantity || 0,
        })) || [
          { sku: "", size: "S", price: 0, quantity: 0 },
          { sku: "", size: "M", price: 0, quantity: 0 },
          { sku: "", size: "L", price: 0, quantity: 0 },
        ]
      );
      setSelectedCategory(food.category[0] || "");
    }
  }, [food]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];

      try {
        setUploading(true);

        const headers = {
          "X-Authorization": token,
        };

        const uploadedImageUrl = await uploadImages(selectedFile, headers);

        setImageURL(uploadedImageUrl.src);
        console.log("Image uploaded successfully:", uploadedImageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("Failed to upload image");
      } finally {
        setUploading(false);
      }
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectedCategory(value);
  };

  const handleBranchChange = (event: SelectChangeEvent<string>) => {
    const selectedBranch = event.target.value;
    setBranchInput(selectedBranch);
  };

  const handleVariantChange = (
    size: string,
    field: "price" | "quantity",
    value: string
  ) => {
    const newValue =
      field === "price" ? parseFloat(value) : parseInt(value, 10);

    setVariants((prevVariants) =>
      prevVariants.map((variant) =>
        variant.size === size ? { ...variant, [field]: newValue } : variant
      )
    );
  };

  const handleAddSize = () => {
    const existingSizes = variants.map((variant) => variant.size);
    let newSize = "";

    if (!existingSizes.includes("S")) newSize = "S";
    else if (!existingSizes.includes("M")) newSize = "M";
    else if (!existingSizes.includes("L")) newSize = "L";

    if (newSize) {
      setVariants((prevVariants) => [
        ...prevVariants,
        { sku: "", size: newSize, price: 0, quantity: 0 },
      ]);
    }
  };

  const handleSave = async () => {
    if (!food) return;

    const updatedFoodData = {
      _id: food._id,
      name: foodName,
      description,
      branch: branchInput,
      category: [selectedCategory],
      url: imageURL,
      variants: variants.map((variant) => ({
        sku: variant.sku,
        size: variant.size,
        price: variant.price,
        quantity: variant.quantity,
      })),
    };

    try {
      const updatedFood = await updateFood(updatedFoodData, token || "");

      setFoods((prevFoods) =>
        prevFoods.map((item) => (item._id === food._id ? updatedFood : item))
      );
      showSnackbar("Cập nhật món ăn thành công!", "success");
      onClose();
    } catch (error) {
      showSnackbar("Cập nhật món ăn thất bại.", "error");
    }
  };

  const handleBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="create-food-modal-title"
      aria-describedby="create-food-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: "8px",
        }}
      >
        <Typography variant="h6" id="create-food-modal-title" fontWeight={550}>
          Chỉnh sửa món ăn
        </Typography>
        <Box component="form" sx={{ mt: 2 }}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "200px",
              cursor: "pointer",
            }}
            onClick={handleBoxClick}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                border: imageURL ? "none" : "2px dashed #ccc",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: imageURL ? "rgba(0, 0, 0, 0.5)" : "#f1f1f1",
                },
              }}
            >
              {uploading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  {imageURL ? (
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={imageURL}
                        alt="Uploaded"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          borderRadius: "8px",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "&:hover::before": {
                            content: '"Change Image"',
                            position: "absolute",
                            color: "#fff",
                            fontSize: "18px",
                            fontWeight: "bold",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            padding: "10px 20px",
                            borderRadius: "4px",
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      Nhấn vào đây để tải ảnh
                    </Typography>
                  )}
                </>
              )}

              <input
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Box>
          </Box>
          <TextField
            fullWidth
            size="small"
            label="Tên món ăn"
            variant="outlined"
            value={foodName}
            onChange={(e) => setFoodName(e.target.value)}
            sx={{ mb: 2, mt: 2 }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            gap={1}
            alignItems="center"
            sx={{ width: "100%" }}
          >
            {variants.map((variant) => (
              <Box key={variant.size} sx={{ flex: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  label={`Kích cỡ: ${variant.size}`}
                  placeholder="Giá tiền"
                  variant="outlined"
                  type="number"
                  sx={{ mb: 2 }}
                  value={variant.price || ""}
                  onChange={(e) =>
                    handleVariantChange(variant.size, "price", e.target.value)
                  }
                />

                <TextField
                  fullWidth
                  size="small"
                  label={`Số lượng: ${variant.size}`}
                  placeholder="Số lượng"
                  variant="outlined"
                  type="number"
                  value={variant.quantity || ""}
                  onChange={(e) =>
                    handleVariantChange(
                      variant.size,
                      "quantity",
                      e.target.value
                    )
                  }
                />
              </Box>
            ))}
          </Stack>
          <Button
            variant="contained"
            fullWidth
            onClick={handleAddSize}
            sx={{ mt: 1, mb: 2.5, textTransform: "none" }}
            disabled={variants.length >= 3}
          >
            Thêm kích cỡ
          </Button>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Chi nhánh</InputLabel>
            <Select
              value={branchInput}
              onChange={handleBranchChange}
              label="Chi nhánh"
              disabled={userRole !== "super-admin"}
            >
              {userRole === "super-admin" ? (
                branches.map((branch) => (
                  <MenuItem key={branch.key} value={branch.key}>
                    {branch.title}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={userBranch || ""} value={userBranch || ""}>
                  {branches.find((branch) => branch.key === userBranch)?.title || "Chi nhánh không xác định"}
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ flex: 1, minWidth: "120px", mb: 2 }}>
            <InputLabel id="category-select-label">Chọn thể loại</InputLabel>
            <Select
              labelId="category-select-label"
              value={selectedCategory}
              onChange={handleCategoryChange}
              input={<OutlinedInput label="Chọn thể loại" />}
              renderValue={(selected) => {
                const selectedCat = categories.find(
                  (category: any) => category.category[0] === selected
                );
                return selectedCat ? selectedCat.desVi : "";
              }}
            >
              {categories.map((category: any) => (
                <MenuItem key={category._id} value={category.category[0]}>
                  {category.desVi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            size="small"
            label="Mô tả món ăn"
            variant="outlined"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mb: 2 }}
          />
          {/* Nút lưu */}
          <Button onClick={onClose}>Đóng</Button>
          <Button variant="contained" onClick={handleSave}>
            Lưu thay đổi
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditFoodModal;
