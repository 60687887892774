import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UserList } from "./interface";
import {
  createUser,
  deleteUser,
  getBranchConfig,
  getListUser,
  updateRoleBranch,
  updateUserStatus,
} from "./service";
import { formatDateTime } from "src/utils/formatCurrency";
import { IoMdMore } from "react-icons/io";
import { useSnackbar } from "src/components/CustomSnackbar";
import CreateUserModal from "./components/CreateUserModal";
import EditUserModal from "./components/EditUserModal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/redux-persist/store";
import { useToken } from "src/utils/token";

const User = () => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const token = useToken();

  const [users, setUsers] = useState<UserList[]>([]);
  const [branchOptions, setBranchOptions] = useState<Record<string, string>>(
    {}
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<UserList | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [selectedPermission, setSelectedPermission] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    fullName: "",
  });
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [userToDelete, setUserToDelete] = useState<UserList | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const roles = [
    { key: "super-admin", label: "Quản trị viên" },
    { key: "admin", label: "Quản lý" },
    { key: "user", label: "Người dùng" },
  ];

  const fetchUsers = async (role?: string) => {
    try {
      setLoading(true);
      const response = await getListUser(role);
      setUsers(
        response.data.filter((user: UserList) => user.user_status !== 0)
      );
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await getBranchConfig("branch");
      const branchMap = response.config.option_content.reduce(
        (acc: Record<string, string>, item: { key: string; title: string }) => {
          acc[item.key] = item.title;
          return acc;
        },
        {}
      );
      setBranchOptions(branchMap);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  useEffect(() => {
    if (userRole !== "super-admin") {
      navigate("/");
    } else {
      fetchUsers(selectedRole);
      fetchBranches();
    }
  }, [userRole, navigate, selectedRole, page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeRole = (event: SelectChangeEvent<string>) => {
    const selectedRole = event.target.value;
    setSelectedRole(selectedRole);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    user: UserList
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
    setSelectedPermission(user.user_role);
    setSelectedBranch(user.branch);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    if (selectedUser) {
      setDisplayName(selectedUser.display_name);
      setOpenModal(true);
    }
    handleCloseMenu();
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    setAnchorEl(null);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleOpenCreateModal = () => setOpenCreateModal(true);
  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
    setNewUser({ email: "", password: "", fullName: "" });
  };

  const handleCreateUser = async () => {
    const { email, password, fullName } = newUser;

    if (!email || !password || !fullName) {
      showSnackbar("Vui lòng điền đầy đủ thông tin!", "warning");
      return;
    }

    try {
      const response = await createUser(email, password, fullName, token || "");
      const createdUser = response.data;

      setUsers((prevUsers) => [
        ...prevUsers,
        {
          _id: createdUser._id,
          display_name: fullName,
          user_role: "user",
          branch: "",
          createdAt: new Date().toISOString(),
          user_status: 0,
        },
      ]);

      handleCloseCreateModal();
      showSnackbar("Tạo người dùng thành công!", "success");
    } catch (error) {
      showSnackbar("Tạo người dùng thất bại", "error");
    }
  };

  const handleSave = async () => {
    if (!selectedUser) return;

    try {
      const { _id } = selectedUser;
      await updateRoleBranch(
        _id,
        selectedBranch,
        selectedPermission,
        token || ""
      );

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === _id
            ? { ...user, branch: selectedBranch, user_role: selectedPermission }
            : user
        )
      );

      setOpenModal(false);
      showSnackbar("Cập nhật thành công", "success");
    } catch (error) {
      showSnackbar("Cập nhật thất bại", "error");
    }
  };

  //   const handleDeleteUser = () => {
  //     console.log("Xóa người dùng:", currentUser);
  //     handleClose();
  //   };

  const handleDeactivateUser = async () => {
    if (userToDelete) {
      try {
        await updateUserStatus(userToDelete._id, "0", token || "");
        showSnackbar("Người dùng đã được vô hiệu hóa", "success");

        fetchUsers(selectedRole);
      } catch (error) {
        showSnackbar("Vô hiệu hóa người dùng thất bại", "error");
      } finally {
        setOpenDeleteConfirm(false);
        setUserToDelete(null);
      }
    }
  };

  const handleDeleteClick = (user: UserList) => {
    setUserToDelete(user);
    setOpenDeleteConfirm(true);
  };

  const isMobile = useMediaQuery("(max-width: 600px)");
  const isMobileSmall = useMediaQuery("(max-width: 375px)");
  const isIphone14 = useMediaQuery("(max-width: 430px)");

  return (
    <Box
      sx={{ mt: isMobile ? 5 : "none" }}
      width="100%"
      p={2}
      pt={4}
      maxWidth={1200}
      margin="0 auto"
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        gap={isMobile ? 2 : 0}
        mb={2}
      >
        <Typography variant="h5" fontWeight={550}>
          Danh sách người dùng
        </Typography>
        <Stack direction="row" gap={2} alignItems="center">
          <Select
            value={selectedRole}
            onChange={handleChangeRole}
            displayEmpty
            sx={{ width: 200, height: 40 }}
          >
            <MenuItem value="">Tất cả vai trò</MenuItem>
            <MenuItem value="super-admin">Quản trị viên</MenuItem>
            <MenuItem value="admin">Quản lý</MenuItem>
          </Select>
          <Button
            variant="contained"
            onClick={handleOpenCreateModal}
            sx={{ textTransform: "none", fontWeight: 550, bgcolor: "#ff7840" }}
          >
            Tạo người dùng
          </Button>
        </Stack>
      </Stack>

      <Box
        sx={{
          width: "100%",
          overflowX: "auto",
          mt: 3,
          borderRadius: "8px",
        }}
      >
        <TableContainer
          sx={{
            minWidth: 680,
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            overflow: "hidden",
          }}
          component={Paper}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : users && users.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                Không có dữ liệu nào
              </Typography>
            </Box>
          ) : (
            <TableContainer
              component={Paper}
              sx={{
                mt: 3,
                minWidth: 680,
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <Table>
                <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: 600 }}>
                      STT
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Tên</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Vai trò</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>
                      Quản lý chi nhánh
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Ngày tạo</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Hành động</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          transition: "0.2s ease",
                          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.02)" },
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell>{user?.display_name}</TableCell>
                        <TableCell>{user?.user_role}</TableCell>
                        <TableCell>
                          {branchOptions[user.branch] || "Chưa quản lý CN"}
                        </TableCell>
                        <TableCell>{formatDateTime(user?.createdAt)}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(event) => handleClick(event, user)}
                          >
                            <IoMdMore />
                          </IconButton>
                        </TableCell>

                        {/* Menu for each user */}
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && selectedUser === user} // Ensure it's opened for the clicked user
                          onClose={handleCloseMenu}
                        >
                          <MenuItem onClick={handleEdit}>
                            Chỉnh sửa quản lý
                          </MenuItem>
                          <MenuItem onClick={() => handleDeleteClick(user)}>
                            Xóa
                          </MenuItem>
                        </Menu>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Dialog
        open={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
      >
        <DialogTitle>Xóa người dùng</DialogTitle>
        <DialogContent>
          <Typography>
            Bạn có chắc chắn muốn xóa người dùng này không?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteConfirm(false)} color="primary">
            Hủy
          </Button>
          <Button onClick={handleDeactivateUser} color="secondary">
            Xóa
          </Button>
        </DialogActions>
      </Dialog>

      <CreateUserModal
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        newUser={newUser}
        setNewUser={setNewUser}
        onCreate={handleCreateUser}
      />

      <EditUserModal
        open={openModal}
        onClose={handleCloseModal}
        selectedPermission={selectedPermission}
        setSelectedPermission={setSelectedPermission}
        selectedBranch={selectedBranch}
        setSelectedBranch={setSelectedBranch}
        branchOptions={branchOptions}
        roles={roles}
        onSave={handleSave}
      />
    </Box>
  );
};

export default User;
